/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.custom-upsell-grid {
  min-height: 250px; 
  display: flex; max-width: 100%; 
  overflow-x: auto; scroll-snap-type: x mandatory; /* Forces snapping to each product */
  gap: 15px; padding: 10px 0; 
}
.custom-upsell-grid::-webkit-scrollbar {
  height: 3px;
}

.custom-upsell-grid::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.custom-upsell-grid::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
.custom-upsell-grid .grid__item-custom  {
  min-height: 200px; float: unset; width: 33%; margin-left: 0px;
  scroll-snap-align: start; /* Ensures each product is aligned to the start of the container */
  flex-shrink: 0;  margin-bottom: 0; 
}
.additional-products-title {
  text-align: center; margin: 10px 0; font-size: 11px;
}
.grid__item-custom .grid__image-ratio--portrait:before {
 padding-bottom: 0;
}
.grid__item-custom .grid__image-ratio img {
  position: static; height: 158px; object-fit: cover;
  width: 100%;
}
.grid__item-custom .grid-product__meta .h6  {
  font-size: 12px; line-height: 1.2; margin-bottom: 5px;
  letter-spacing: 1.1px;
}
.grid__item-custom .grid-product__price {
  font-size: 11px; line-height: 1.2; margin-top: 5px; 
  letter-spacing: 1.1px; 
}
.grid__item-custom .grid-product__meta {
  padding-top: 0;     padding-bottom: 10px;
}
.grid__item-custom .grid-product__image-mask {
  overflow: visible; 
}
.grid__item-custom .grid__image-ratio {
  background: #fff; 
}
.grid__item-custom .grid-product__link:after {
  display: none; 
}
.grid__item-custom [data-center-text=true] .grid-product__meta {
  line-height: 1.2; padding: 5px 0; 
}
.cart-additional-upsells{
  padding: 20px var(--drawer-gutter); 
  max-width: 100%; position: relative; 
  position: relative; padding-bottom: 25px; 
  margin-bottom: 15px;   background: #f8f8f8; 
}
.upsell-product-right .custom-form {
  margin: 0; padding: 0; width: max-content; 
}
.cart-notes {
  padding-inline: var(--drawer-gutter); 
}
.buttons-container {
  position: absolute; max-width: 100%; display: flex; 
  justify-content: space-between; width: 100%; 
  height: calc(50% + 20px); align-items: flex-end; 
}
.scroll-button {
  position: absolute; top: 50%; transform: translateY(-40%);
  background: #fff; border-radius: 50%; color: #000; 
  border: none; height: 34px; cursor: pointer; font-weight: 600; 
  display: flex; align-items: center;  z-index: 1; 
  font-size: 18px; font-family: monospace; transition: all 0.3s ease; 
  width: 35px; justify-content: center; box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.scroll-button:hover , .scroll-button:focus {
  height: 40px; width: 41px /* Scale the button to 110% of its size */
}
.scroll-button .flickity-button-icon {
  height: 18px; position: static; width: 18px; 
  display: block; 
}
.prev-button {
  left: 3px;
}

.next-button {
  right: 3px;
}

.scroll-button:disabled {
  background: rgba(0, 0, 0, 0.2);
  cursor: not-allowed;
}

.custom-form {
  padding: 5px 0px; margin-bottom: 10px; 
  display: flex; flex-direction: column; 
  width: 100%; padding-top: 0; 
}
.custom-form .d-block {
   display: block !important;
  background-image: var(--urlIcoSelectWhite);
}
.custom-form .btn--full[data-add-to-cart] , .custom-form .d-block {
  font-size: 9px !important; line-height: 24px;  background-color: var(--colorBtnPrimary) ; 
   font-weight: 400; color: var(--colorBtnPrimaryText); letter-spacing: 1px; 
  height: 24px;  padding: 0 10px; border: none; 
}
.custom-form .btn--full[data-add-to-cart][hidden]{
  display: none !important; 
}
.custom-upsell-grid .grid__item-custom .custom-variants {
  
}
.cart__item {
  max-width: 100%; 
}
.cart__item .cart__image a {
  height: 112px; 
}
.cart__item .cart__image img {
  width: auto; object-fit: cover; 
  height: 100%; 
}
.cart__item .cart__image {
  flex: 0 0 85px; height: 112px;  
}
.cart__item .js-qty__wrapper  {
  border: 1px solid var(--colorDrawerBorder); 
}
.cart__item .js-qty__num {
  padding-block: 0; border: unset !important; 
}
.cart__item .cart__item-title .d-flex {
  display: flex; justify-content: space-between;
  align-items: flex-start;
}
.cart__item .cart__item-title  .cart__item-name {
  margin-bottom: 0; 
  font-size: 11px;line-height: 11px; font-weight: 700;
}
.cart__item--variants {
  margin-bottom: 0 !important; 
}
.cart__item--variants > div {
  font-size: 11px; line-height: 11px;
}
#CartDrawer.drawer , form#CartPageForm , .upsell-item-wrap , .upsell-item-wrap > * ,
#CartDrawer .drawer__fixed-header .drawer__title {
  font-family: "Montserrat", sans-serif !important;
}
.cart__item .cart-info {
  display: flex; flex-direction: column;
  gap: 8px; flex-grow: 1;
}
.cart__item .cart-price {
  margin-left: 10px; flex-grow: 1;  margin-top: -5px;
  display: flex; position: relative;
  flex-direction: column; align-items: flex-end;
}

.cart__item .cart__item-sub>div:first-child {
  margin: 0; display: flex; justify-content: space-between;
  width: 100%; 
}
.cart__item .cart__remove {
  display: flex;  font-size: 12px; text-decoration: underline; 
}
.cart__item .cart__remove a {
  min-width: 50px; display: flex; justify-content: flex-start; 
}
.cart__item .cart__item-sub {
  align-self: end; 
}

#CartDrawer.drawer  .drawer__scrollable {
  padding-left: 0; padding-right: 0; 
}
#CartDrawer.drawer .cart__item {
  padding-inline: var(--drawer-gutter);
}
upsell-item.upsell-item-wrap {
  width: 100%; margin-bottom: 0px; background-color: #f8f8f8;
  position: relative; overflow: visible; 
  padding-inline: 0; margin-top: -21px; 
  max-width: 100%; display: block;
}
upsell-item.upsell-item-wrap:after {
  content: ""; z-index: 1;
  display: block; width: 50px; height: 50px;
  background-color: #f8f8f8; transform: rotate(45deg) translate(50%);
  top: 6px; right: 50%; position: absolute;
}
.upsell-title {
  text-align: center; padding: 17px 0; font-size: 11px; 
  line-height: 11px; position: relative; z-index: 2; 
}
.upsell-product-card {
  display: flex; padding-bottom: 17px; 
  padding-inline: var(--drawer-gutter);     align-items: center;
}
.upsell-product-card img {
  width: 85px; height: auto; object-fit: cover;
  margin-right: 16px;
}
.upsell-item-wrap .custom-form .btn--full[data-add-to-cart] , .upsell-item-wrap  .custom-form .d-block  {
    height: 30px; display: flex; margin-top: 10px; 
    align-items: center; justify-content: center; 
    padding: 0 10px !important; font-size: 11px !important; 
}
.custom-form .d-block , .upsell-item-wrap  .custom-form .d-block {
  padding: 0 10px 0 20px !important; 
}
.upsell-product-right {
  width: calc(100% - 101px);
  display: flex; justify-content: space-between;
  height: 100%;  align-items: flex-start;
  flex-direction: column;
}
upsell-item.upsell-item-wrap .grid-product {
  padding: 0 22px 0 30px; display: flex;
  align-items: center; position: relative;
  z-index: 2; background: inherit; 
}
.upsell-product-infos {
  display: flex; flex-direction: column;
  gap: 10px; width: 100%;
}
.upsell-product-infos .upsell-product-title {
  margin: 0; font-size: 11px; width: 100%; 
  display: flex; justify-content: space-between;
}
.upsell-product-title>span:first-child {
  font-weight: 700; font-size: 11px; max-width: 70%; 
  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.upsell-product-title>span:last-child {
    font-size: 11px; line-height: 11px;
}
.upsell-padding-top{
  height: 36px; width: 100%; display: block; background-color: #fff;
  margin-top: 
}

.absolute-url {
  position: absolute;  top: 0;
  left: 0; height: 100%;
  display: block; width: 100%;
}
.link-clicked .text {
  display: none; 
}

.loading-icon {
  display: none; border: 3px solid #ddd;
  border-top: 3px solid #949c7b; /* Spinner color */
  border-radius: 50%; width: 16px; height: 16px;
  animation: spin 1s linear infinite; /* Space between text and spinner */
}
.link-clicked .loading-icon {
  display: flex; 
}
/* Animation for spinning */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.upsell-item-wrap + .cart__item {
  margin-top: var(--drawer-gutter); 
}
#CartDrawer .drawer__fixed-header .drawer__header {
  min-height: unset;  height: 100%; border: unset; 
}
#CartDrawer .drawer__fixed-header .drawer__title {
  font-size: 14px;
}
#CartDrawer .drawer__fixed-header {
  width: 100%; height: 45px; background-color: #949c7b; display: flex ; min-height: unset; 
  position: relative; color: #fff; align-items: center; justify-content: center; font-size: 14px;
}

.drawer.is-empty .drawer__cart-empty {
  padding-inline: var(--drawer-gutter);
}
@media only screen and (max-width: 750px){
  .cart__item .cart__item-title .cart__item-name , .cart__item .cart-price {
    font-size: 10px; 
  }
  .cart__item--variants>div , .cart__item .cart__remove a {
    font-size: 9px; 
  }
  .custom-upsell-grid .grid__item-custom {
    width: 35%; margin-left: 0; 
  }
  .grid__item-custom .grid__image-ratio img {
    height: 145px; 
  }
  .upsell-product-card img {
    width:60px; 
  }
  .upsell-product-right .custom-form {
    width: 120px; 
  }
  .upsell-product-infos .upsell-product-title {
    flex-direction: column; gap: 8px; 
  }
  .upsell-product-right {
    max-width: calc(100% - 85px);
    width: calc(100% - 85px);
  }
  .cart__item .cart-price {
    align-items: flex-start; 
  }
}
.site-footer .footer__title {
  font-family: "Montserrat", sans-serif !important;
  text-transform: uppercase; letter-spacing: 3.36px;
  font-size: .8em;
}
 .header-item--left .site-nav  {
      margin-left: -12px; display: flex; 
 }
.site-nav__dropdown .svg-mask--landscape {
  padding-bottom: 100% !important; 
}
.site-nav__dropdown .megamenu__colection-image {
    margin-bottom: 20px; display: block; 
}
.stamped-review-header-title {
  font-family: 'Open Sans', sans-serif !important;
}
.product-block--header {
  margin-bottom: 0rem !important;
  padding-bottom: 0rem !important;
}
.stamped-product-reviews-badge {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .cart__item .cart__image img {
    width: 100%; 
  }
}








